.login-component-card {
   

    @media only screen and (min-width: 200px) {
       
            width: 100%;
        
    }

    @media only screen and (min-width: 600px) {
        
            width: 40%;
        
    }

    @media only screen and (min-width: 1000px) {}
}