.controls-pad-container {
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: calc(100vh - 50vh);
  width: calc(100vh - 50vh);

  @media screen  and (min-width:400px) {
    max-height: 400px;
    max-width: 400px;
  }

  @media screen  and (min-width:600px) {
    max-height: 600px;
    max-width: 600px;
  }
  @media screen  and (min-width:1000px) {
    max-height: 1000px;
    max-width: 1000px;
  }
  z-index: 1;
  border-radius: 50%;
}

.control-pad-on {
  border: 1px solid green;
}

.control-pad-off {
  border: 1px solid red;
}

.controls-pad-center {
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: calc(100vh - 95vh);
  width: calc(100vh - 95vh);
  z-index: 1;
  border-radius: 50%;
}

.controls-pad-forward {
  position: absolute;
  height: 46%;
  width: 5%;
  border-bottom: none;
  border-top: none;
}

.controls-pad-turn-up-right {
  position: absolute;
  height: 50%;
  width: 50%;
  transform: translate(50%);
  border-radius: 0 100% 0 0;
  z-index: -1;
}

.controls-pad-turn-down-right {
  position: absolute;
  height: 50%;
  width: 50%;
  transform: translate(50%) rotate(90deg);
  border-radius: 0 100% 0 0;
  top: 50%;
  z-index: -1;
}

.controls-pad-turn-down-left {
  position: absolute;
  height: 50%;
  width: 50%;
  transform: translate(-50%) rotate(180deg);
  border-radius: 0 100% 0 0;
  top: 50%;
  z-index: -1;
}

.controls-pad-turn-up-left {
  position: absolute;
  height: 50%;
  width: 50%;
  transform: translate(-50%) rotate(270deg);
  border-radius: 0 100% 0 0;
  z-index: -1;
}

.controls-pad-spin-left {
  position: absolute;
  height: 5%;
  width: 46%;
  top: 50%;
  left: 0px;
  transform: translatey(-50%);
  border-right: none;
  border-left: none;
}

.controls-pad-spin-right {
  position: absolute;
  height: 5%;
  width: 46%;
  top: 50%;
  left: 54%;
  transform: translatey(-50%);
  border-right: none;
  border-left: none;
}

.controls-pad-back {
  position: absolute;
  height: 46%;
  width: 5%;
  top: 54%;
  border-bottom: none;
  border-top: none;
}

.controls-pad-activated {
  border: 1px solid green;
}

.controls-pad-deactivated {
  border: 1px solid red;
}
